var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseModal',{ref:"modal",attrs:{"title":"Kost aanmaken","max-width":"tw-max-w-xl"}},[_c('FormulateForm',{attrs:{"name":"createCostForm"},on:{"submit":_vm.createCost},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isLoading = ref.isLoading;
return [_c('FormulateInput',{attrs:{"type":"select","name":"product","label":"Product","options":_vm.productOptions,"disabled":_vm.commissionFeeSelected,"validation":"bail|required|min:1","validation-messages":{
        min: 'Product is verplicht'
      },"error-behavior":"submit","outer-class":"tw-mb-1"},on:{"change":_vm.setDescription}}),(_vm.entityType === 'property' && !_vm.commissionFeeSelected)?_c('button',{staticClass:"tw-underline",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.setCommissionFeeProduct.apply(null, arguments)}}},[_vm._v(" Manuele ereloonkost registreren? Klik hier. ")]):_vm._e(),_c('FormulateInput',{attrs:{"type":"number","name":"amount","min":"0","step":".01","label":"Bedrag","placeholder":"Bedrag","validation":"bail|required|min:0.01"},scopedSlots:_vm._u([(_vm.createCostValues.amount && _vm.createCostValues.vat_percentage)?{key:"help",fn:function(){return [_vm._v(" Deze kost zal worden opgeslagen als "),_c('span',{staticClass:"tw-font-bold"},[_vm._v(" "+_vm._s(_vm.currency(_vm.amountExclVat(_vm.createCostValues)))+" ")]),_vm._v(" (excl. btw) ")]},proxy:true}:null],null,true)}),_c('FormulateInput',{attrs:{"name":"vat_percentage","options":{'': 'Exclusief btw / 0% btw', '21': 'Inclusief 21% btw'},"type":"radio","label":"Deze kost is"}}),_c('FormulateInput',{attrs:{"type":"text","name":"description","label":"Beschrijving","placeholder":"Beschrijving","validation":"max:50,length"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('label',{staticClass:"formulate-label",attrs:{"for":"description"}},[_vm._v(" Beschrijving "),_c('Tooltip',{staticClass:"tw-inline-block",scopedSlots:_vm._u([{key:"popper",fn:function(){return [_c('div',{staticClass:"tw-text-center"},[_vm._v(" Adresgegevens worden automatisch toegevoegd in de factuurlijn en mogen hier niet worden ingegeven ")])]},proxy:true}],null,true)},[_c('i',{staticClass:"fas fa-info-circle"})])],1)]},proxy:true}],null,true)}),_c('FormulateErrors',{staticClass:"tw-text-right"}),_c('div',{staticClass:"tw-flex tw-justify-end"},[_c('FormulateInput',{attrs:{"type":"submit","disabled":isLoading,"outer-class":"tw-mt-2"}},[_c('i',{class:[
            'fas tw-mr-1',
            isLoading ? 'fa-spinner-third fa-spin' : 'fa-save'
          ]}),_vm._v(" Opslaan ")])],1)]}}]),model:{value:(_vm.createCostValues),callback:function ($$v) {_vm.createCostValues=$$v},expression:"createCostValues"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }