<template>
  <BaseModal ref="modal" title="Kost aanmaken" max-width="tw-max-w-xl">
    <FormulateForm
      #default="{ isLoading }"
      v-model="createCostValues"
      name="createCostForm"
      @submit="createCost"
    >
      <!-- Option 0 is a disabled placeholder option, which should not be submitted -->
      <FormulateInput
        type="select"
        name="product"
        label="Product"
        :options="productOptions"
        :disabled="commissionFeeSelected"
        validation="bail|required|min:1"
        :validation-messages="{
          min: 'Product is verplicht'
        }"
        error-behavior="submit"
        outer-class="tw-mb-1"
        @change="setDescription"
      />
      <button
        v-if="entityType === 'property' && !commissionFeeSelected"
        type="button"
        class="tw-underline"
        @click.prevent="setCommissionFeeProduct"
      >
        Manuele ereloonkost registreren? Klik hier.
      </button>
      <FormulateInput
        type="number"
        name="amount"
        min="0"
        step=".01"
        label="Bedrag"
        placeholder="Bedrag"
        validation="bail|required|min:0.01"
      >
        <template #help v-if="createCostValues.amount && createCostValues.vat_percentage">
          Deze kost zal worden opgeslagen als
          <span class="tw-font-bold">
            {{ currency(amountExclVat(createCostValues)) }}
          </span>
          (excl. btw)
        </template>
      </FormulateInput>
      <FormulateInput
        name="vat_percentage"
        :options="{'': 'Exclusief btw / 0% btw', '21': 'Inclusief 21% btw'}"
        type="radio"
        label="Deze kost is"
      />
      <FormulateInput
        type="text"
        name="description"
        label="Beschrijving"
        placeholder="Beschrijving"
        validation="max:50,length"
      >
        <template #label>
          <label for="description" class="formulate-label">
            Beschrijving
            <Tooltip class="tw-inline-block">
              <i class="fas fa-info-circle" />
              <template #popper>
                <div class="tw-text-center">
                  Adresgegevens worden automatisch toegevoegd in de factuurlijn en mogen hier niet worden ingegeven
                </div>
              </template>
            </Tooltip>
          </label>
        </template>
      </FormulateInput>

      <FormulateErrors class="tw-text-right" />

      <div class="tw-flex tw-justify-end">
        <FormulateInput type="submit" :disabled="isLoading" outer-class="tw-mt-2">
          <i
            :class="[
              'fas tw-mr-1',
              isLoading ? 'fa-spinner-third fa-spin' : 'fa-save'
            ]"
          />
          Opslaan
        </FormulateInput>
      </div>
    </FormulateForm>
  </BaseModal>
</template>

<script>
import { currency } from '@/utils/helpers'
import { Tooltip } from 'floating-vue'
import { successModal, errorModal, warningModal } from '@/modalMessages'

import { getSupplierProducts } from '@/services/orders'
import { createCost, getPropertyCommissionFeeProduct } from '@/services/costs'

export default {
  name: 'CreateCostModal',
  components: {
    Tooltip
  },
  props: {
    property: {
      type: Object,
      required: false
    },
    project: {
      type: Object,
      required: false
    }
  },
  data () {
    return {
      products: [],
      commissionFeeSelected: false,
      createCostValues: {
        is_invoiceable: true,
        description: '',
        vat_percentage: ''
      }
    }
  },
  computed: {
    productOptions () {
      let results = [{ value: 0, label: 'Selecteer een product', disabled: true }]
      results = [...results, ...this.products.map(item => { return { value: item.id, label: `${item.product.name} (${item.supplier.name})` } })]
      return results
    },
    entityType () {
      if (this.property) return 'property'
      return 'project'
    }
  },
  methods: {
    currency,
    async show () {
      const transaction_type = this.entity?.transaction_type
      const params = {}
      if (transaction_type) params.transaction_type = transaction_type
      const response = await getSupplierProducts(params)
      this.$set(this.createCostValues, 'is_invoiceable', true) // Fix re-opening form after closing it
      this.products = response?.data?.results
      this.$refs.modal.show()
    },
    hide () {
      this.$refs.modal.hide()
    },
    async createCost (data) {
      try {
        data.amount = this.amountExclVat(data)
        data[this.entityType] = this.property ? this.property.id : this.project.id
        const response = await createCost(data)
        this.hide()
        successModal('Kost werd succesvol aangemaakt')
        this.commissionFeeSelected = false
        return response
      } catch (error) {
        console.error(error)
        this.$formulate.handle(error, 'createCostForm')
      }
    },
    setDescription () {
      const product = this.products.find(item => item.id === parseInt(this.createCostValues.product))
      this.$set(this.createCostValues, 'description', product.product.name)
    },
    async setCommissionFeeProduct () {
      try {
        this.commissionFeeSelected = true
        const response = await getPropertyCommissionFeeProduct(this.entityId)
        this.products.push(response.data)
        this.$set(this.createCostValues, 'product', response.data.id)
        this.setDescription()
        return response.data
      } catch (error) {
        this.commissionFeeSelected = false
        if (error?.response?.data?.code === 'PROPERTY_WITHOUT_TRANSACTION') {
          return warningModal('Dit dossier heeft geen transactie. Er kan geen ereloonkost worden geregistreerd.')
        }
        return errorModal('Er ging iets mis bij het inladen van het ereloonproduct.')
      }
    },
    amountExclVat ({ amount, vat_percentage }) {
      let calculation = null
      if (!vat_percentage) calculation = parseFloat(amount)
      else calculation = parseFloat(amount) * 100 / (100 + parseFloat(vat_percentage))
      return calculation.toFixed(2)
    }
  }
}
</script>
