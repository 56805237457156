<template>
  <div class="row">
    <div class="col-sm-3">
      <PanelBox title="Mappenstructuur" class="tw-my-0">
        <Tree
          :nodes="folderStructure"
          :edit-children="true"
          :selected-node="selectedFolder"
          identifier="id"
          @selected="selectFolder"
          @new="showFolderForm('new')"
          @edit="showFolderForm('edit', $event)"
          @remove="removeFolder"
        />
      </PanelBox>

      <div class="tw-grid md:tw-grid-cols-2 tw-gap-2 tw-my-2">
        <button
          type="button"
          title="Document genereren"
          :disabled="!allowCreation"
          class="tw-flex-grow tw-py-2 tw-rounded tw-bg-gray-e9 hover:tw-bg-opacity-80"
          @click="createNewDocument"
        >
          <i class="far fa-file-plus tw-mr-1" /> Genereren
        </button>
        <button
          type="button"
          title="Bestanden uploaden"
          class="tw-flex-grow tw-py-2 tw-rounded tw-bg-gray-e9 hover:tw-bg-opacity-80"
          @click="showEntityInfoSheetsModal"
        >
          <i class="far fa-clipboard-list tw-mr-1" /> Infofiches
        </button>
      </div>

      <EntityFileUpload
        v-if="allowCreation"
        :property="property"
        :project="project"
        :folder="selectedFolder"
        @fetchFiles="refreshFiles"
        @askToCreateCost="handleCostCreation"
      />
      <CreateCostModal ref="costCreateModal" :property="property" :project="project"/>
    </div>
    <div class="col-sm-9">
      <SignaturePackageTable v-if="selectedFolder.name === 'eSignatures'" :property="property" />
      <EntityFileTable
        v-else
        ref="entityFileTable"
        :selected-folder="selectedFolder"
        :folders="children"
        :project="project"
        :property="property"
      />
    </div>
    <FormulateForm
      #default="{ isLoading }"
      v-model="folderForm"
      name="folderAddEditForm"
      @submit="submit"
    >
      <Modal
        ref="folderAddEditModal"
        size="xs"
        :title="mode === 'edit' ? 'Bewerk map' : 'Nieuwe map'"
        @hide="$formulate.reset('folderAddEditForm', {})"
      >
        <FormulateInput
          name="name"
          type="text"
          label="Naam"
          validation="required"
          :show-required-label="true"
        />
        <template #footer>
          <div class="tw-flex tw-flex-row tw-space-x-4 tw-justify-end">
            <FormulateInput
              type="submit"
              :disabled="isLoading"
              :input-class="['tw-w-full']"
              :outer-class="['tw-w-full md:tw-w-auto']"
            >
              <i
                :class="[
                  'fas tw-mr-2',
                  isLoading ? 'fa-spinner-third fa-spin' : 'fa-check'
                ]"
              />
              Opslaan
            </FormulateInput>
            <FormulateInput
              type="button"
              :input-class="['tw-bg-error tw-w-full']"
              :outer-class="['tw-w-full md:tw-w-auto']"
              @click="hideFolderForm"
            >
              <i class="far fa-times tw-mr-2" /> Annuleren
            </FormulateInput>
          </div>
        </template>
      </Modal>
    </FormulateForm>
    <CreateEntityFileModal
      ref="createDocumentModal"
      :property="property"
      :project="project"
      :folder="selectedFolder"
      @fetchFiles="refreshFiles"
    />
    <EntityInfoSheetsModal
      ref="entityInfoSheetsModal"
      :property="property"
      :project="project"
      @info-sheets-updated="processInfoSheetsUpdate"
    />
  </div>
</template>

<script>
import Tree from '@/components/iam/Tree'
import PanelBox from '@/components/iam/PanelBox'

import CreateEntityFileModal from '@/components/properties/CreateEntityFileModal'
import EntityFileUpload from '@/components/properties/EntityFileUpload'
import EntityFileTable from '@/components/properties/EntityFileTable'
import SignaturePackageTable from '@/components/properties/SignaturePackageTable'
import Modal from '@/components/iam/Modal'
import EntityInfoSheetsModal from '@/components/properties/EntityInfoSheetsModal'
import CreateCostModal from '@/components/properties/CreateCostModal'

import { mapActions } from 'vuex'
import {
  startLoadingModal,
  errorModal,
  successModal,
  questionModal
} from '@/modalMessages'
import {
  getProjectFileFolders,
  createProjectFileFolder,
  editProjectFileFolder,
  deleteProjectFileFolder
} from '@/services/projects'
import {
  getPropertyFileFolders,
  createPropertyFileFolder,
  editPropertyFileFolder,
  deletePropertyFileFolder
} from '@/services/properties'

export default {
  name: 'EntityFilePanel',
  components: {
    SignaturePackageTable,
    Tree,
    CreateEntityFileModal,
    EntityFileUpload,
    CreateCostModal,
    EntityFileTable,
    PanelBox,
    Modal,
    EntityInfoSheetsModal
  },
  props: {
    project: {
      type: Object,
      default: null
    },
    property: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      selectedFolder: null,
      children: [],
      folderForm: {},
      mode: 'new',
      allowCreation: true
    }
  },
  computed: {
    folderStructure () {
      const structure = [
        // id: 'unset' is used by the filter `folder_id` by the files endpoint (`/api/v3/property/:id/files` or `/api/v3/project/:id/files`).
        // It is used to notify the backend to return only folder-less files.
        // Not providing any filter returns all the files.
        {
          id: 'unset',
          name: 'Bestanden',
          createChildren: true,
          editChildren: true,
          removeChildren: true,
          keepOpen: true,
          allowDrop: true,
          children: this.children
        }
      ]
      if (this.property) structure.push({ id: 'signatures', name: 'eSignatures' })
      return structure
    }
  },
  created () {
    this.init()
  },
  methods: {
    ...mapActions('properties', ['loadPropertySignaturePackages']),

    init () {
      this.selectedFolder = this.folderStructure[0]
      return this.loadFileFolders()
    },
    selectFolder (folder) {
      this.selectedFolder = folder
      this.allowCreation = this.selectedFolder.id !== 'signatures'
    },
    showFolderForm (mode, node) {
      this.mode = mode
      if (mode === 'edit') this.folderForm = node
      this.$refs.folderAddEditModal.show()
    },
    hideFolderForm () {
      this.$refs.folderAddEditModal.hide()
    },
    refreshFiles () {
      this.$refs.entityFileTable.init()
    },
    createNewDocument () {
      this.$refs.createDocumentModal.show()
    },

    async loadFileFolders () {
      try {
        let response
        if (this.property) response = await getPropertyFileFolders(this.property.id)
        if (this.project) response = await getProjectFileFolders(this.project.id)
        this.children = response?.data?.results.map(folder => ({ ...folder, allowDrop: true }))
        return response
      } catch (error) {
        console.log(error)
        errorModal('Fout bij het laden van de mappen, probeer het opnieuw.')
      }
    },
    async submit (data) {
      try {
        startLoadingModal('Map opslaan...')
        const folder = this.mode === 'edit' ? await this.editFolder(data) : await this.createNewFolder(data)
        this.selectedFolder = folder.data
        const response = await this.loadFileFolders()
        this.hideFolderForm()
        successModal('Map opgeslagen')
        return response
      } catch (error) {
        console.log(error)
        errorModal('Fout bij het maken van de map, probeer het opnieuw.')
      }
    },
    createNewFolder (data) {
      if (this.property) return createPropertyFileFolder(this.property.id, data)
      if (this.project) return createProjectFileFolder(this.project.id, data)
    },
    editFolder (data) {
      if (this.property) return editPropertyFileFolder(this.property.id, data)
      if (this.project) return editProjectFileFolder(this.project.id, data)
    },
    async removeFolder (folder) {
      try {
        const result = await questionModal('Weet je zeker dat je de map wilt verwijderen?')
        if (!result.value) return

        startLoadingModal('De map wordt verwijderd...')
        if (this.property) await deletePropertyFileFolder(this.property.id, folder.id)
        if (this.project) await deleteProjectFileFolder(this.project.id, folder.id)

        const response = await this.loadFileFolders()
        this.selectFolder(this.folderStructure[0])
        successModal('De map is verwijderd')
        return response
      } catch (error) {
        console.log(error)
        errorModal('Fout bij het verwijderen van de map, probeer het opnieuw.')
      }
    },
    showUploadModal () {
      this.$refs.uploadModal.show()
    },
    showEntityInfoSheetsModal () {
      this.$refs.entityInfoSheetsModal.show()
    },
    getInfoSheetsFolder () {
      return this.folderStructure?.[0]?.children?.find(folder => folder.name === 'Infofiches')
    },
    async processInfoSheetsUpdate () {
      let infoSheetsFolder = this.getInfoSheetsFolder()
      if (!infoSheetsFolder) {
        await this.loadFileFolders()
        infoSheetsFolder = this.getInfoSheetsFolder()
      }
      if (this.selectedFolder === infoSheetsFolder) this.refreshFiles()
      else this.selectFolder(infoSheetsFolder)
    },
    async handleCostCreation () {
      const result = await questionModal('Wil je een kost aanmaken?.', 'Kost aanmaken')
      if (!result.value) return
      return this.$refs.costCreateModal.show()
    }
  }
}
</script>
